<template>
  <div class="detail_cont">
    <!-- 设备详情 -->
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i>
      <p>{{equDeatil.equname}}</p>
      <span class="back" @click="goBack" >返回</span>
    </div>
    <div class="pro_cont flexCB">
      <!-- 设备基本信息 -->
      <div class="pro_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
          <div class="udi_k"></div>
          <p class="ud_t">设备基本信息</p>
        </div>
        <div class="ui_cont">
          <el-row>
            <el-col :span="12">
              <p>单位名称：{{equDeatil.companyname}}</p>
              <p>设备编号：{{equDeatil.equimei}}</p>
              <p>安全管理人：{{equDeatil.username}}</p>
            </el-col>
            <el-col :span="12">
              <p>设备名称：{{equDeatil.equname}}</p>
              <p>安装位置：{{equDeatil.adderss}}</p>
              <p>电话：{{equDeatil.userphone}}</p>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 设备告警统计 -->
      <div class="pro_right">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
          <div class="udi_k"></div>
          <p class="ud_t">设备告警统计</p>
        </div>
        <div class="alarm_cont">
          <div v-for="(item, index) in alarmList" :key="index"
          :class="{alarm_one:index === 0, alarm_two:index === 1, alarm_three:index === 2}" @click="goalarmDiaog(item, index)">
            <p class="alarm_num">{{item.value}}</p>
            <p class="alarm_info">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="pro_container">
      <div class="pro_one">
        <div class="rig_tit">
          <i class="el-icon-caret-right" style="margin:0 10px;"></i>
          <p>{{equDeatil.equname}}</p>
        </div>
        <div class="paddBox flexCB">
          <Host :equDeatil="equDeatil" :refsh="refsh" style="width: 100%;height: 100%"></Host>
          <Set :equDeatil="equDeatil" @jbFresh="jbFresh" style="width: 100%;height: 100%"></Set>
        </div>
      </div>
      <div class="pro_two">
        <div class="rig_tit">
          <i class="el-icon-caret-right" style="margin:0 10px;"></i>
          <p>参数统计</p>
        </div>
        <div class="paddErt">
          <Parameter :equDeatil="equDeatil"></Parameter>
        </div>
      </div>
    </div>

    <!-- 关联设备 -->
    <div class="pro_foot" v-if="equDeatil.equtype != 6">
      <div class="rig_title">
        <i class="el-icon-caret-right" style="margin:0 10px;"></i>
        <p>关联设备</p>
      </div>
      <div class="equ_content" v-if="companyEquip.length != 0">
        <div class="equip_list" v-for="(item,index) in companyEquip" :key="index">
          <div class="list_item"
            @click="goVideo(item)">
              <el-row>
                <el-col :span="8">
                  <p><img :src="item.equtypesvg" /></p>
                </el-col>
                <el-col :span="12">
                  <p class="item_desc" style="width:150px;overflow:hidden; white-space:nowrap; text-overflow:ellipsis;"  :title="item.equname">{{item.equname}}</p>
                  <p class="item_desc" style="width:150px;overflow:hidden; white-space:nowrap; text-overflow:ellipsis;"  :title="item.adderss">{{item.adderss}}</p>
                </el-col>
              </el-row>
          </div>
        </div>
      </div>
      <div style="width:100%;height:100%;text-align:center;" v-if="companyEquip.length == 0">
        <img src="../../assets/img/notdata.png" style="width:150px"/>
        <p>暂无关联设备</p>
      </div>
    </div>

    <!-- DTU关联设备 -->
    <div class="pro_foot" v-if="equDeatil.equtype == 6">
      <div class="rig_title">
        <i class="el-icon-caret-right" style="margin:0 10px;"></i>
        <p>关联设备</p>
      </div>
      <div class="equ_content" v-if="DTUEquip.length != 0">
        <div class="dtu_list" v-for="(item,index) in DTUEquip" :key="index">
          <div class="dtu_item"
            @click="goDTU(item)">
              <div>
                <p class="dtu_desc">{{item.dtutype}}</p>
                <p class="dtu_desco">{{item.count}}</p>
                <!-- <p class="dtu_desct">设备数量</p> -->
              </div>
          </div>
        </div>
      </div>
      <div style="width:100%;height:100%;text-align:center;" v-if="DTUEquip.length == 0">
        <img src="../../assets/img/notdata.png" style="width:150px"/>
        <p>暂无关联设备</p>
      </div>
    </div>

    <!-- DTU关联设备弹窗 -->
    <el-dialog :title="dtutype" :visible.sync="DTUShow" width="1200px" center class="dtuialog hdBg hdTb">
      <div class="alarm_box">
      <div class="flexCB" >
        <div>
          <el-input  placeholder="请输入搜索内容" class="contInput" v-model="name"></el-input>
          <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
          <el-button class="resetbtn" @click="handleReset">重置</el-button>
        </div>
      </div>
        <div class="pro_table hidTable">
          <el-table
            :data="DTUList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="设备名称"
              prop="dtuname">
            </el-table-column>
            <el-table-column
              label="设备编号"
              prop="dtucode">
            </el-table-column>
            <el-table-column
              label="设备地址"
              prop="dtuaddr">
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="indexPage">
        </el-pagination>
      </div>
    </el-dialog>

    <!-- 可视对讲弹窗 -->
    <el-dialog title="可视对讲" :visible.sync="ezuShow" width="1300px" center class="ezuDialog hdBg hdTb" :close-on-click-modal="false" :before-close="videoCancel">
      <div class="ezu_container">
        <div class="ezu_video">
          <div id="videoContainer" ref="videoContainer" style="width:100%;height:100%;"></div>
        </div>
        <div class="ezu_info">
          <div class="flexSC">
            <div class="ud_left">
              <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
                <div class="udi_k"></div>
                <p class="ud_t">设备基本信息</p>
              </div>
              <p class="ud_c">单位名称：{{ ezuDetail.companyname }}</p>
              <p class="ud_c">设备名称：{{ ezuDetail.equname }}</p>
              <p class="ud_c">设备编号：{{ ezuDetail.equimei }}</p>
              <p class="ud_c">安装位置：{{ ezuDetail.adderss }}</p>
              <p class="ud_c">安全管理人：{{ ezuDetail.username }}</p>
              <p class="ud_c">电话：{{ ezuDetail.userphone }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 视频监控弹窗 -->
    <el-dialog title="视频监控" :visible.sync="flvShow" width="1200px" center class="hlsialog hdBg hdTb" :close-on-click-modal="false" :before-close="flvOrHlsCancel">
      <div class="hls_container">
        <div class="hls_video" style="width: 60%">
          <video ref="videoElement" id="videoElement" controls autoplay muted style="width: 100%; height:100%"></video>
        </div>
        <div class="ezu_info"  style="width: 40%">
          <div class="flexSC">
            <div class="ud_left">
              <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
                <div class="udi_k"></div>
                <p class="ud_t">设备基本信息</p>
              </div>
              <p class="ud_c">单位名称：{{ flvDetail.companyname }}</p>
              <p class="ud_c">设备名称：{{ flvDetail.equname }}</p>
              <p class="ud_c">设备编号：{{ flvDetail.equimei }}</p>
              <p class="ud_c">安装位置：{{ flvDetail.adderss }}</p>
              <p class="ud_c">安全管理人：{{ flvDetail.username }}</p>
              <p class="ud_c">电话：{{ flvDetail.userphone }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 告警列表 -->
    <el-dialog title="设备告警" :visible.sync="alarmListShow" center class="alarmDialog hdBg hdTb" width="1480px" :close-on-click-modal="false">
      <AlarmList  :equDeatil="equDeatil" :type="type" @showAlarm="showAlarm"></AlarmList>
    </el-dialog>

    <!-- 故障列表 -->
    <el-dialog title="设备故障" :visible.sync="faultLsitShow" center class="alarmDialog hdBg hdTb" width="1480px" :close-on-click-modal="false">
      <FaultList  :equDeatil="equDeatil" :type="type"></FaultList>
    </el-dialog>

    <!-- 误报列表 -->
    <el-dialog title="设备误报" :visible.sync="distortListShow" center class="alarmDialog hdBg hdTb" width="1480px" :close-on-click-modal="false">
      <DistortList  :equDeatil="equDeatil" :type="type"></DistortList>
    </el-dialog>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js'
import AlarmList from '@/components/work/alarmList.vue'
import FaultList from '@/components/work/faultList.vue'
import DistortList from '@/components/work/distortList.vue'
import Host from '@/components/work/host.vue'
import Set from '@/components/work/set.vue'
import Parameter from '@/components/work/parameter.vue'
import { alarmStatis, assocEquinfo, DTUassocequInfo, getDTUassoc } from '@/http/api/work'
import { equCurrent } from '@/http/api/equip'
import Recorderx from 'recorderx'
const rc = new Recorderx()
import Hls from 'hls.js'
import $ from 'jquery'
import flvjs from 'flv.js'
import { constants } from 'os';

export default {
  props: ['equDeatil', 'unitId'],
  components: {
    Host,
    Parameter,
    AlarmList,
    FaultList,
    DistortList,
    Set
  },
  data () {
    return {
      detailInfo: {},
      alarmList: [],
      companyEquip: [],
      ezuShow: false,
      ezuDetail: {},
      flvShow: false,
      flvDetail: {},
      alarmListShow: false,
      faultLsitShow: false,
      distortListShow: false,
      DTUEquip: [],
      DTUShow: false,
      name: '',
      page: 1,
      size: 10,
      total: 0,
      DTUList: [],
      dtutype: '',
      player: '',
      type: '',
      refsh: false,
      timer: '',
      timer1: '',
      hlsPlayer: null,
      flvPlayer: null,
      spurl: ''
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  mounted () {
    window.URL = window.URL || window.webkitURL
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
  },
  created () {
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    if (this.equDeatil.equtype == 6) {
      this.getInit()
    }
    this.init()
  },
  destroyed () {
    clearTimeout(this.timer)
    clearTimeout(this.timer1)
  },
  methods: {
    init () {
      alarmStatis({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.alarmList = res.data
        } else {
          this.alarmList = []
        }
      })

      assocEquinfo({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.companyEquip = res.data
        } else {
          this.companyEquip = []
        }
      })
    },
    // DTU设备
    getInit () {
      const params = {
        equid: this.equDeatil.equid,
        companyid: this.equDeatil.companyid
      }
      DTUassocequInfo(params).then(res => {
        if (res.status === '1') {
          this.DTUEquip = res.data
        } else {
          this.DTUEquip = []
        }
      })
    },
    goDTU (item, index) {
      this.DTUShow = true
      this.dtutype = item.dtutype
      this.DTUInit()
    },
    DTUInit () {
      const params = {
        equid: this.equDeatil.equid,
        companyid: this.equDeatil.companyid,
        page_index: this.page,
        page_size: this.size,
        name: this.name,
        dtutype: this.dtutype
      }
      getDTUassoc(params).then(res => {
        if (res.status === '1') {
          this.DTUList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.DTUList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.DTUInit()
    },
    handleSearch () {
      this.page = 1
      this.DTUInit()
    },
    handleReset () {
      this.page = 1
      this.name = ''
      this.DTUInit()
    },
    goVideo (item) {
      if (item.equtype === '2') { // 可视对讲
        this.ezuShow = true
        this.ezuDetail = item
        this.getMicrophone()
        this.$nextTick(() => {
          this.getVideo()
        })
      } else { // 1视频监控
        this.flvShow = true
        this.flvDetail = item
        equCurrent({ equid: this.flvDetail.equid }).then(res => {
          if (res.status === '1') {
            this.$nextTick(() => {
              this.spurl = res.data.video_token
              this.getHlsOrFlvVideo()
            })
          }
        })
      }
    },
    // 视频监控
    getHlsOrFlvVideo () {
      if (this.spurl.indexOf('hls') > 0) { /* Hls */
        if (Hls.isSupported()) {
          var videoBox = this.$refs.videoElement
          this.hlsPlayer = new Hls()
          this.hlsPlayer.loadSource(this.spurl)
          this.hlsPlayer.attachMedia(videoBox)
          this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
            this.$refs.videoElement.play()
          })
        } else {
          console.log('不支持的hls格式')
        }
      } else if (this.spurl.indexOf('flv') > 0) { /* Flv */
        if (flvjs.isSupported()) {
          var videoBox = this.$refs.videoElement
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            isLive: true,
            hasAudio: false,
            hasVideo: true,
            url: this.spurl
          })
          this.flvPlayer.attachMediaElement(videoBox)
          this.flvPlayer.load()
          this.flvPlayer.play()
        } else {
          console.log('不支持flv格式')
        }
      }
    },
    // 获取麦克风权限
    getMicrophone () {
      rc.start().then(() => {
        console.log('获取麦克风成功')
      }).catch(error => {
        console.log('失败原因' + error)
      })
    },
    // 可视对讲
    getVideo () {
      equCurrent({ equid: this.ezuDetail.equid }).then(res => {
        if (res.status === '1') {
          this.player = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: 'videoContainer',
            accessToken: res.data.video_token,
            url: res.data.equip_port,
            template: 'voice', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
            footer: ['talk', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
            plugin: ['talk'],
            audio: 1,
            // width: 840,
            height: 550
          })
        }
      })
    },
    /* 可视对讲关闭 */
    videoCancel () {
      $('#videoContainer').empty()
      this.ezuShow = false
    },
    /* 视频联动关闭 */
    flvOrHlsCancel () {
      this.flvShow = false
      $('#videoElement').empty()
    },
    // 设备弹窗
    goalarmDiaog (item, index) {
      this.type = item.type
      if (index === 0) {
        this.alarmListShow = true
      } else if (index === 1) {
        this.faultLsitShow = true
      } else {
        this.distortListShow = true
      }
    },
    goBack () {
      this.$emit('goback')
    },
    // 告警展示
    showAlarm (res) {
      this.alarmDetailShow = true
      this.alarmKey = res
    },
    // 动态参数刷新
    jbFresh () {
      this.refsh = !this.refsh
    }
  }
}
</script>

<style scoped>
.detail_cont {
  width: 100%;
  height: 100%;
  padding: 20px 40px 0px 40px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
}
.pro_cont {
  width: 100%;
  margin-top: 30px;
}
.pro_left, .pro_right {
  width: 50%;
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ui_cont p {
  font-size: 16px;
  font-family: 'PingFang SC';
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin: 0px 0px 15px;
}
.ui_cont {
  width: 100%;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
}
.alarm_cont {
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}
.alarm_one {
  width: 200px;
  height: 100px;
  background: rgba(241, 55, 55, 0.1);
  border: 1px solid #F13737;
  opacity: 1;
  border-radius: 10px;
  color: #F13737;
}
.alarm_two {
  width: 200px;
  height: 100px;
  background: rgba(255, 148, 76, 0.1);
  border: 1px solid #FF944C;
  opacity: 1;
  border-radius: 10px;
  color: #FF944C;
}
.alarm_three {
  width: 200px;
  height: 100px;
  background: rgba(66, 238, 255, 0.1);
  border: 1px solid #42EEFF;
  opacity: 1;
  border-radius: 10px;
  color: #42EEFF;
}
.alarm_num {
  text-align: center;
  font-size: 40px;
  /*color: #F13737;*/
  padding-top: 20px;
  line-height: 30px;
}
.alarm_info {
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 40px;
}
.pro_container {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.pro_two {
  width: 50%;
}
.pro_one {
  width: 50%;
}
.rig_tit {
  position: absolute;
  width: 48%;
  height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  background: linear-gradient(91deg, rgba(33, 110, 243, 0.5) 0%, rgba(36, 104, 240, 0) 100%);
}
.paddBox {
  width: 98%;
  padding: 30px 0;
}
.paddErt {
  width: 98%;
  padding: 30px 0;
}
.pro_foot {
  width: 100%;
}
.equ_content {
  width: 100%;
  height: 185px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  scrollbar-width: none;
}
.equ_content::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.equip_list {
  width: 20%;
  /* height: 100px; */
  position: relative;
  /* border: 1px solid #fff; */
  box-sizing: border-box;
  /* margin-top: 20px; */
}
.list_item {
  width: 80%;
  color: #42EFFF;
  font-size: 14px;
  border: 1px solid #00D4FF;
  background: rgba(6, 26, 52, 0.5);
  /* padding: 10px; */
  box-sizing: border-box;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  /* line-height: 30px; */
  /* margin: 10px auto 25px; */
}
.list_item img {
  padding-left: 10px;
}
.item_desc {
  font-size: 14px;
  padding-top: 10px;
  color: #42EFFF;
}
.dtu_list {
  width: 10%;
  height: auto;
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
}
.dtu_item {
  width: 90%;
  color: #42EFFF;
  font-size: 14px;
  border: 1px solid #00D4FF;
  background: rgba(6, 26, 52, 0.5);
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 25px;
  line-height: 25px;
  text-align: center;
}
.dtu_desc {
  color: #42EFFF;
}
.dtu_desco {
  font-size: 32px;
  margin-top: 10px;
}
.dtu_desco, .dtu_desct {
  color: #FFAD42;
}
.ezu_container, .hls_container{
  width: 100%;
  display: flex;
}
.ezu_video, .hls_video{
  width: 80%;
}
.ezu_info {
  width: 20%;
  margin-left: 20px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  /* font-weight: bold; */
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
.hidTable {
  height: 500px;
}
</style>
