<template>
  <!-- 联网单位 -->
  <div class="unit_container">
    <!-- 单位列表 -->
    <div class="unit_left" v-show="!isShowList && !isShowDetail">
      <div class="rig_title">
        <i class="el-icon-caret-right" style="margin:0 10px;"></i>
        <p>单位列表</p>
        <div class="unit_list" @click="selectUnitList"  :class="showUnitList == true? 'unit_active': '' ">
          <span class="iconfont iconshuzhuangtu_1"></span>列表
        </div>
        <div class="unit_tree" @click="selectUnitTree" :class="showUnitTree == true? 'unit_active': '' ">
          <span class="iconfont iconshuzhuangtu"></span>单位树
        </div>
      </div>
      <div class="alarm_search" v-if="showUnitList">
        <el-input placeholder="请输入单位名称" class="contInput"  v-model="filterValue"></el-input>
        <el-button class="contsearch">搜索</el-button>
      </div>
      <div class="alarm_search" v-if="showUnitTree">
        <el-input placeholder="请输入单位名称" class="contInput"  v-model="treeValue"></el-input>
        <el-button class="contsearch">搜索</el-button>
      </div>
      <div class="left_cont">
        <ul class="company" v-if="showUnitList">
          <li class="company_li" v-for="(item,index) in unitList"
          :key="index" :class="item.companyid == unitId?'company_active':''"
          @click="selectCompany(item,index)">
              <i class="iconfont iconunit"></i>
              {{item.companyname}}
          </li>
        </ul>
        <div v-if="showUnitTree" class="company_tree">
          <el-tree
          ref="companyTree"
          class="filter-tree"
          default-expand-all
          highlight-current
          node-key="companyid"
          :props="defaultProps"
          :data="data"
          :filter-node-method="filterNode"
          @node-click="handleNodeClick">
			    </el-tree>
        </div>
      </div>
    </div>
    <!-- 单位信息 -->
    <div class="unit_right" v-if="!isShowList && !isShowDetail">
      <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>单位信息</p></div>
      <div class="right_cont" ref="rightCont">
        <div class="unit_info" ref="unitInfoHeight">
          <div class="ui_img">
            <img class="ui_logo" v-if="companyInfo.companyimage != '' " :src="companyInfo.companyimage"/>
            <img class="ui_logo" v-if="companyInfo.companyimage == ''" src="../../assets/img/unit_default.png" />
          </div>
          <div class="ui_cont">
<!--            <div class="ui_title"><div class="title_line"></div><div>单位基本信息</div></div>-->
            <el-row>
              <el-col :span="9">
                <p>单位名称：{{companyInfo.companyname}}</p>
                <p>消控室电话：{{companyInfo.companyphone}}</p>
                <p>单位等级：{{companyInfo.companylevelname}}</p>
                <p>单位法人：{{companyInfo.companyuser}}</p>
                <p>辖区消防队：{{companyInfo.firename}}</p>
              </el-col>
              <el-col :span="15">
                <p>统一信用码：{{companyInfo.companycode}}</p>
                <p>单位地址：{{companyInfo.companyress}}</p>
                <p>行业性质：{{companyInfo.industryname}}</p>
                <p>法人电话：{{companyInfo.suser_phone}}</p>
                <!-- <p>维保单位：{{companyInfo.mainname}}</p> -->
              </el-col>
            </el-row>
          </div>
          <div class="ui_optimi">
            <div class="ui_p" @click="showGrad"></div>
            <div class="ui_score">
              <ScoreLoading v-if="ScoreShow" :isResult="isResult" :companyScore="companyScore" @clear="clear"></ScoreLoading>
<!--              <span>{{companyInfo.companyscore}}</span>分-->
            </div>
            <div class="uiBtn" @click="handleScore">一键评分</div>
          </div>
        </div>

        <div class="company_equip">
          <div class="eq_title">
            <div class="er_item" @click="tabClick(1)" :class="{'selected': tabId == 1}">设备信息</div>
            <div class="er_item" @click="tabClick(2)" :class="{'selected': tabId == 2}">微消防站</div>
            <div class="er_item" @click="tabClick(3)" :class="{'selected': tabId == 3}">巡检信息</div>
            <div class="er_item" @click="tabClick(4)" :class="{'selected': tabId == 4}">消防预案</div>
            <div class="er_item" @click="tabClick(5)" :class="{'selected': tabId == 5}">告警信息</div>
            <div class="er_item" @click="tabClick(6)" :class="{'selected': tabId == 6}">报表统计</div>
          </div>
          <div class="eqCont" :style="{ height: scrollHeight + 'px' }">
            <Eqinfo v-if="tabId == 1" @lookEquList='lookEquList' :unitId="unitId"></Eqinfo>
            <Firestation v-if="tabId == 2" :unitId="unitId"></Firestation>
            <!-- <Check v-if="tabId == 3" :unitId="unitId"></Check> -->
            <CheckPlan v-if="tabId == 3" :unitId="unitId"></CheckPlan>
            <Fireplan v-if="tabId == 4" :unitId="unitId"></Fireplan>
            <Alarminfo v-if="tabId == 5" :unitId="unitId"></Alarminfo>
            <ImportFrom v-if="tabId == 6" :unitId="unitId"></ImportFrom>
          </div>
        </div>
      </div>
    </div>

    <!-- 设备列表 -->
    <div class="list_container" v-if='isShowList && !isShowDetail'>
      <ProEquList :equKey="equKey" :unitId="unitId" :refreshPage="refreshPage" @back="back"  @goProjectDetail="goProjectDetail(arguments)"></ProEquList>
    </div>
    <!-- 设备详情 -->
    <div class="detail_container" v-if='isShowDetail'>
      <!-- <ProDetail @goback="goback" :equDeatil="equDeatil"  v-if="typeId != '1'&& typeId != '2' && typeId != '20'"></ProDetail>-->
      <ProDetail @goback="goback" :equDeatil="equDeatil"  v-if="equipIdList(typeId)"></ProDetail>
      <!--  视频联动  -->
      <Monitor v-if="typeId === '1'" :equDeatil="equDeatil"  @goback="goback"></Monitor>
      <!--  萤石云可视对讲  -->
      <Visual v-if="typeId === '2'" :equDeatil="equDeatil"  @goback="goback"></Visual>
      <!--  AI摄像头  -->
      <Aicamera v-if="typeId === '20'" :equDeatil="equDeatil"  @goback="goback"></Aicamera>
      <!--  智能断路器  -->
      <Breaker v-if="typeId === '21'" :equDeatil="equDeatil"  @goback="goback"></Breaker>
    </div>
    <!-- 评分规则 -->
    <el-dialog title="单位评分" :visible.sync="gardDialog" center width="910px" class="scoreDialog hdBg hdTb">
      <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>单位评分规则</p></div>
      <div class="score_t">
        <span style="font-size: 18px; color: #FFFFFF; font-weight: 500;">{{ companyInfo.companyname }}：</span>
        <!-- <span style="font-size: 18px; color: #196DF7; font-weight: 900;"></span> --><span style="font-size: 18px; color: #FFAD42; font-weight: 900;">{{ companyInfo.companyscore }}分</span>
      </div>
      <ul>
        <li class="lis">
          <div class="one">状态</div>
          <div class="two">类型</div>
          <div class="three">分值</div>
          <div class="four">得分</div>
        </li>
        <li v-for="item in scoreList"  :key="item.index" :class="item.typedil? 'lisOther': 'lis'">
          <div class="one">
            <img  v-if="item.isok == true"  src="../../assets/img/wan.png" alt="">
            <img  v-if="item.isok == false" src="../../assets/img/yichang.png" alt="">
          </div>
          <div class="two">{{ item.type }}<p class="two_t">{{ item.typedil }}</p></div>
          <div class="three">{{ item.peak }}</div>
          <div style="font-size: 14px; color: #FFAD42; font-weight: bold;" class="four">{{ item.sorce }}</div>
        </li>
      </ul>
      <div class="text">
        <span style="margin-left:20px;">提示：安全评分标准判断单位最低分数为 0分 最高分数为 100分。</span>
        <img  src="../../assets/img/wan.png" alt="" ><span>表示状态正常无需处理</span>
        <img  src="../../assets/img/yichang.png" alt="" ><span>表示状态异常需进行及时处理</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Eqinfo from '@/components/work/eqinfo.vue'
import Firestation from '@/components/work/firestation.vue'
// import Check from '@/components/work/check.vue'
import CheckPlan from '@/components/work/checkPlan.vue'
import Fireplan from '@/components/work/fireplan.vue'
import Alarminfo from '@/components/work/alarminfo.vue'
import ProEquList from '@/components/work/proEqulist.vue'
import ProDetail from '@/components/work/proDetail.vue'
import Monitor from '@/components/work/monitor.vue'
import Visual from '@/components/work/visual.vue'
import ImportFrom from '@/components/work/importFrom.vue'
import ScoreLoading from '@/components/work/scoreLoading'
import Aicamera from '@/components/work/Aicamera.vue'
import Breaker from '@/components/work/breaker'
import { getcompanySorce } from '@/http/api/unit'
import { getcomDetails } from '@/http/api/work'
import { companyList, getAddress } from '@/http/api/customer'

export default {
  name: 'workUnit',
  components: {
    Eqinfo,
    Firestation,
    // Check,
    CheckPlan,
    Fireplan,
    Alarminfo,
    ProEquList,
    ProDetail,
    Monitor,
    Visual,
    ImportFrom,
    ScoreLoading,
    Aicamera,
    Breaker
  },
  data () {
    return {
      companyInfo: {},
      tabId: 1,
      isShowList: false,
      unitList: [],
      select: null,
      unitId: '',
      equKey: null,
      isShowDetail: false,
      equDeatil: null,
      isShowHls: false,
      isShowEZU: false,
      activeNames: '1',
      active: 0,
      stepList: [],
      gardDialog: false,
      scoreList: [],
      filterValue: '',
      isHaveUnitId: '', // 首页地图传的单位id
      scrollHeight: 0,
      showUnitList: false,
      showUnitTree: false,
      data: [],
      treeValue: '',
      isResult: 0,
      ScoreShow: false,
      companyScore: '',
      refreshPage: 1,
      defaultProps: {
        children: 'children',
        label: 'label',
        name: 'name',
        companyid: 'companyid'
      }
    }
  },
  watch: {
    filterValue (val) {
      var newListData = []
      if (val) {
        this.unitList.filter(item => {
          if (item.companyname.indexOf(val) !== -1) {
            newListData.push(item)
          }
        })
        this.unitList = newListData
      } else {
        this.init()
      }
    },
    treeValue (val) {
      this.$refs.companyTree.filter(val)
    }
  },
  mounted () {
    const rigHeight = this.$refs.rightCont.offsetHeight
    const unitHeight = this.$refs.unitInfoHeight.offsetHeight
    this.scrollHeight = rigHeight - unitHeight - 160
  },
  created () {
    this.isHaveUnitId = this.$route.query.data
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      // 单位列表
      companyList({ name: this.filterValue }).then(res => {
        if (res.status === '1') {
          this.showUnitList = true
          this.unitList = res.data
          if (this.isHaveUnitId === undefined) {
            this.unitId = this.unitList[0].companyid // 默认展示第一条单位
          } else {
            this.unitId = this.isHaveUnitId.companyid // 如果从首页进入
          }
          this.getcompanyDetails()
        } else {
          this.unitList = []
        }
      })
    },
    // 单位信息
    getcompanyDetails () {
      getcomDetails({ companyid: this.unitId }).then(res => {
        if (res.status === '1') {
          this.companyInfo = res.data
          this.companyScore = res.data.companyscore
          this.ScoreShow = true
        } else {
          this.companyInfo = ''
        }
      })
    },
    handleScore () {
      this.isResult = 1
    },
    clear () {
      this.isResult = 0
    },
    selectCompany (item, index) {
      this.unitId = item.companyid
      this.getcompanyDetails()
    },
    // 选择单位列表
    selectUnitList () {
      this.showUnitList = true
      this.showUnitTree = false
      this.init()
    },
    // 选择单位树
    selectUnitTree () {
      this.showUnitTree = true
      this.showUnitList = false
      this.getInit()
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 单位树
    getInit () {
      getAddress({}).then(res => {
        if (res.status === '1') {
          const arr = this.treeDataFormat(res.data)
					this.data = arr
          this.$nextTick(() => {
            if (arr[0].children[0].children[0].children[0].companyid) {
              this.unitId = arr[0].children[0].children[0].children[0].companyid
              this.getcompanyDetails()
            }
            this.$refs.companyTree.setCurrentKey(this.unitId)
          })
        }
      })
    },
    // 通过递归的形式，获取角色下所有三级权限的id，并保存到 defKeys 数组中
    // getLeafKeys(node) {
    //   // 如果当前 node 节点不包含 children 属性，则是三级节点
    //   if (!node.children.length == 0) {
    //   // 除了最后一级所有节点disabled为true
    //     node.disabled = true;
    //   }
    //   node.children.forEach(item => this.getLeafKeys(item))
    // },
    handleNodeClick (item, node, self) {
      if (item.children.length === 0) {
        this.unitId = item.companyid
        this.getcompanyDetails()
      }
    },
    treeDataFormat (treeJson) {
      if (!Array.isArray(treeJson) || treeJson.length <= 0) {
        return []
      }
      let res = []
      treeJson.forEach((item, i) => {
        res.push({
          label: item.name !== undefined ? item.name : item.companyname,
          companyid: item.companyid !== undefined ? item.companyid : item.companyid,
          name: item.name !== undefined ? item.name : item.companyname,
          children: (item.value && item.value.length > 0) ? this.treeDataFormat(item.value) : []
        })
      })
      return res
    },
    lookEquList (res) {
      this.isShowList = true
      this.refreshPage = 1
      this.equKey = res
    },
    back() {
      this.isShowList = false
    },
    goback () {
      this.isShowDetail = false
    },
    goProjectDetail (res) {
      this.isShowDetail = true
      this.equDeatil = res[0]
      this.typeId = res[0].equtype
      this.refreshPage = res[1]
    },
    tabClick(type) {
      this.tabId = type
    },
    // 评分规则
    showGrad () {
      this.gardDialog = true
      getcompanySorce({ companyid: this.unitId }).then(res => {
        if (res.status === '1') {
          this.scoreList = res.data
        } else {
          this.scoreList = []
        }
      })
    },
    equipIdList(val) { // 设备类型id组合
      var arr = ['1', '2', '20', '21']
      var isShow = false
      if (arr.indexOf(val) == -1) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    }
  }
}
</script>

<style>
.el-tree-node:focus > .el-tree-node__content { /*设置选中的样式 */
  background-color: transparent !important;
}
.el-tree-node__content:hover { /*设置鼠标飘过的颜色 */
  background: transparent !important;
  color: #FFAD42;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content { /*current选中的样式 */
  color: #FFAD42;
  font-weight: bold;
  background-color: transparent !important;
}
</style>
<style scoped>
.unit_container {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding-top: 70px;
  justify-content: space-between;
  overflow: hidden;
  background: url('../../assets/unit_img/1.png') no-repeat;
  background-size: 100% 100%;
  /*overflow-y: scroll;*/
  /*scrollbar-width: none;  !*火狐兼容*!*/
}
/*.unit_container::-webkit-scrollbar {*/
/*  width: 0px; !* 谷歌兼容 *!*/
/*}*/
/* 设备列表 */
.list_container {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: url('../../assets/unit_img/1.png') no-repeat;
  background-size: 100% 100%;
  /*overflow-y: scroll;*/
  /*scrollbar-width: none;*/
}
.list_container::-webkit-scrollbar {
  width: 0px;
}
/* 设备详情 */
.detail_container {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: url('../../assets/unit_img/1.png') no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}
.detail_container::-webkit-scrollbar {
  width: 0px;
}
.unit_left,.unit_right{
  width: 23%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.unit_right{
  width: 83%;
}
.unit_list {
  position: absolute;
  right: 20%;
  top: 0;
  font-size: 14px;
  cursor: pointer;
}
.unit_tree {
  position: absolute;
  right: 0%;
  top: 0%;
  font-size: 14px;
  cursor: pointer;
}
.unit_list .iconfont,.unit_tree .iconfont{
  font-size: 13px;
  margin-right: 5px;
}
.unit_active {
  color: #FFAD42;
}
.right_cont{
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  padding-top: 40px;
  box-sizing: border-box;
}
.ui_title {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
}
.title_line{
  width: 4px;
  height: 16px;
  background: #196DF7;
  margin-right: 10px;
}
.unit_info {
  width: 100%;
  display: flex;
  font-size: 14px;
}
.ui_logo {
  border-radius: 4px;
}
.ui_logo{
  width: 170px;
  height: 170px;
}
.ui_cont{
  width: 70%;
  color: #fff;
  padding: 0 15px;
  box-sizing: border-box;
  margin-left: 20px;
}
.ui_cont p{
  margin: 0px 0px 15px;
}
.ui_optimi {
  width: 30%;
  margin-top: 20px;
  position: relative;
}
.ui_p {
  width: 26px;
  height: 26px;
  background: url("../../assets/unit_img/4.png") no-repeat;
  position: absolute;
  left: 100px;
  top: 0;
  cursor: pointer;
}
.ui_score{
  width: 110px;
  height: 110px;
  font-size: 14px;
  color: #A5B7CC;
}
.ui_score span{
  font-size: 50px;
  font-weight: 700;
  color: #42EEFF;
}
.ui_score span{
  font-size: 50px;
  font-weight: 700;
  color: #42EEFF;
}
.uiBtn{
  width: 90px;
  margin-left: 10px;
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #4295FF;
  font-size: 14px;
  background: url("../../assets/img/unit_btn.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.company_equip {
  width: 100%;
  height: auto;
  position: relative;
}
.eq_title {
  width: 100%;
  height: 40px;
  background: rgba(136,172,252,0.16);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  margin: 40px 0;
}
.eqCont{
  width: 100%;
  height: auto;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.eqCont::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.er_item {
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}
.selected {
  background: rgba(136,172,252,0.16);
  border-radius: 25px;
  color: #FFAD42;
}
/* 搜索框 */
.alarm_search {
  width: 100%;
  height: 35px;
  margin: 20px 0;
  display: flex;
  position: relative;
}
.contInput{
  width: calc(100% - 60px);
}
.alarm_search >>> .contInput .el-input__inner{
  width: 100%;
  background: rgba(136,172,252,0.16);
  border: none;
  color: #6F7E90;
  border-radius: 20px 0px 0px 20px;
}
.alarm_search >>> .el-button--default {
  width: 57px;
  height: 35px;
  line-height: 35px;
  background: rgba(136,172,252,0.16);
  color: #FFAD42;
  border-radius: 0px 30px 30px 0px;
  padding: 0;
  border: none;
  position: absolute;
  right: 0;
}
.contsearch {
  width: 60px;
  border-radius: 0px 30px 30px 0px;
}
.left_cont {
  width: 100%;
  height: calc(100% - 105px);
  overflow: hidden;
}
.company {
  width: 100%;
  height: 90%;
  margin: 0px;
  padding: 0px;
  color: #A5B7CC;
  overflow-y: auto;
  scrollbar-width: none;
}
.company::-webkit-scrollbar,.right_cont::-webkit-scrollbar {
  display: none;
}
.company_li {
  cursor: pointer;
  width: 100%;
  padding: 12px 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.company_active {
  background: rgba(136,172,252,0.16);
  color: #FFAD42;
}
/* 单位树 */
.company_tree {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.filter-tree{
  width: 100%;
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: transparent;
  color: #A5B7CC;
  scrollbar-width: none;
}
.filter-tree::-webkit-scrollbar{display: none;width:0;}
/*.filter-tree >>> .el-tree-node__content:hover{*/
/*  background: none;*/
/*}*/
/*.filter-tree >>> .is-current{*/
/*	background: none;*/
/*}*/
/*.filter-tree >>> .el-tree-node:focus > .el-tree-node__content {*/
/*  background: none !important;*/
/*  color: #FFAD42 !important;*/
/*}*/
/* 评分规则 */
.scoreDialog >>> .el-dialog__body {
  padding: 30px;
}
ul {
  padding: 0;
}
.lis,.lisOther {
  width: 850px;
  height: 40px;
  line-height: 40px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 2px;
}
.lisOther{
  height: auto;
  line-height: 20px;
  padding: 8px 0px;
  align-items: center;
}
.score_t {
  margin: 20px 0;
}
.lis img,.lisOther img {
  width: 16px;
  height: 16px;
  margin: auto 0;
  text-align: center;
}
.one {
  width: 20%;
  text-align: center;
}
.two {
  width: 70%;
  text-align: left;
}

.three {
  width: 20%;
  text-align: center;
}
.four {
  width: 20%;
  text-align: center;
}
.text {
  font-size: 12px;
  font-weight: 500;
  color: #A5B7CC;
}
.text img {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  margin-right: 2px;
  margin-left: 2px;
}
</style>
