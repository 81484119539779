// 客服  联网单位
import { get, post, put, deletefn } from '@/http/http'

// 联网单位
export const companyList = p => get('v1/customer/companylist', p)

// 单位树查询
export const getAddress = p => get('v1/customer/getaddress', p)

// 告警信息查询
export const getalarmInfo = p => get('v1/customer/getalarminfo', p)

// 处理信息
export const getdealInfo = p => get('v1/customer/getdealinfo', p)
